import utils from './utils';

const cardBlack = '#121212';
const white = '#ffffff';

const colors = {
  neutral: {
    white,
    black: '#0C0D10',
    grey: '#f0f0f0',
    cardBorder: '#575757',
    cardBlack,
    offWhite: utils.alphaHex(white, 80),
    eggWhite: '#e4e4e4',
    textGrey: '#777777',
  },
  primary: {
    light: '#c8fb74',
    main: '#c2fb65',
    dark: '#b4fa43',
  },
  secondary: {
    main: '#e36ad7',
  },
  tertiary: {
    main: '#ef8a4e',
  },
  error: {
    main: '#ff5f85',
  },
  gradients: {
    blackGradient: `linear-gradient(180deg, ${cardBlack} 0%, ${utils.alphaHex(
      cardBlack,
      0
    )} 100%)`,
  },
};

export const webColors = {
  neutral: {
    white,
    trueBlack: '#000000',
    black: '#0B0B0B',
    grey: '#9d9d9d',
    cardBlack: '#151515',
  },
  primary: {
    light: '#5e3b8d',
    main: '#b74eef',
    dark: '#9035ff',
  },
  secondary: {
    main: '#03ffe6',
  },
  tertiary: {
    main: '#25cafe',
  },
  error: {
    main: '#d62c55',
  },
  gradients: {
    blackGradient: `linear-gradient(180deg, #565656 0%, ${utils.alphaHex(
      '#565656',
      0
    )} 100%)`,
  },
};

export default colors;
